import React from 'react'

import classNames from 'classnames'
import DefaultLayout from '../../layout/default'

import CtaForm from '../../pagesSections/index/CtaForm'

import pageStyles from '../../styles/_V2/pagesStyles/styles.module.scss'
import styles from '../styles/_V2/docs.module.scss'
import AdaptiveLink from '../../components/AdaptiveLink'
import { pagesLinks } from '../../pages-data/common/links'

export default function PagePrivacyPolicy() {
  const metaTags = [
    // {
    //   name: 'keywords',
    //   content: 'Автоматизация ресторана, автоматизация кафе, автоматизация бара, автоматизация столовой, ipad pos, касса на ipad, калькуляция, расчет себестоимости, технологическая карта, российская система автоматизации',
    // },
    {
      name: 'description',
      content: 'Лицензионный договор является офертой ООО «Квик Ресто» — система автоматизации предприятий общественного питания Quick Resto — программа для полноценного складского учёта в общепите онлайн, удобный кассовый терминал на планшете, CRM и настраиваемая система отчетов.',
    },
    {
      property: 'og:url',
      content: 'https://quickresto.ru/licenseAgreement/annex3',
    },
    {
      property: 'og:title',
      content: 'Лицензионный договор на право использования системы автоматизации Quick Resto',
    },
    {
      property: 'og:description',
      content: 'Лицензионный договор является офертой ООО «Квик Ресто» — система автоматизации предприятий общественного питания Quick Resto — программа для полноценного складского учёта в общепите онлайн, удобный кассовый терминал на планшете, CRM и настраиваемая система отчетов.',
    },
    {
      property: 'og:type',
      content: 'website',
    },
  ]

  return (
    <DefaultLayout
      title="Приложение № 3"
      metaTags={metaTags}
      mainWrapperClassName={classNames(
			  pageStyles.pageWrapper,
			  pageStyles.pageGray,
			  styles.docs,
      )}
      linkCanonical="https://quickresto.ru/licenseAgreement/annex3/"
      footerClassName={pageStyles.pageSection}
    >
      <section
        className={classNames(styles.docs__container, pageStyles.pageSection)}
      >
        <div className={styles.docs__content}>
          <h1 className={styles.bigMb}>
            Специальные условия Лицензии на Опциональную программу -
            «Электронная карта лояльности»
          </h1>

          <div>
            <p>
              Приложение № 3
              к Лицензионному договору на право использования
              программы для ЭВМ «Система автоматизации предприятий
              общественного питания «Quick Resto»» и иных
              программных продуктов ООО «Квик Ресто»
            </p>

          </div>

          <div>
            <p/>

            <p>1. Настоящее Приложение, как и Лицензионный договор, является офертой <br/> ООО&nbsp;<strong>&laquo;Квик
              Ресто&raquo;</strong>&nbsp;(ОГРН 5137746099046, ИНН&nbsp;7726734798, КПП 771401001, 125167 г. Москва,
              Ленинградский пр. 36, стр. 30, пом. 96), именуемого в дальнейшем Лицензиар, Пользователю, именуемому в
              дальнейшем Лицензиат или Оператор персональных данных.</p>
            <p>2. Под офертой согласно настоящему Приложению № 3 (далее &ndash; &laquo;Приложение № 3&raquo;)
              подразумевается предложение Лицензиара Лицензиату приобрести за дополнительное вознаграждение простую
              (неисключительную) лицензию на результат интеллектуальной деятельности ООО &laquo;Квик
              Ресто&raquo; &ndash; разработанную Лицензиаром программу для ЭВМ &ndash; Сервис управления электронными
              картами &laquo;Электронная карта лояльности&raquo; (далее также &ndash; &laquo;Сервис &laquo;Карта
              лояльности&raquo;&raquo;).</p>
            <p>Сервис &laquo;Карта лояльности&raquo; является информационной панелью, которая позволяет Лицензиату как
              предприятию или сети предприятий общественного питания самостоятельно формировать и редактировать для
              клиентов Лицензиата электронные карты лояльности, вносить в указанные карты необходимые данные, начислять
              различные скидки и бонусы, в том числе в зависимости от заказов клиента блюд и напитков.</p>
            <p>2.1 Порядок предоставления сервиса &laquo;Карта лояльности&raquo;:</p>
            <p>Лицензиат самостоятельно настраивает дизайн &laquo;карты лояльности&raquo; (цвет фона, название и логотип
              заведения, иконку для площадок AppleWallet и GooglePay), вносит правовые документы и настраивает правила
              бонусной программы, в соответствии с правилами размещения данной информации, и отправляет Лицензиару
              запрос на модерацию (проверку данных) посредством кнопки &laquo;отправить на проверку&raquo; в облачном
              бэк-офисе.</p>
            <p>Лицензиар проводит проверку заполненных данных на предмет размещения запрещенных материалов (изображения
              алкогольной продукции, изображения и текстов о продуктах курения &ndash; табачной продукции и иных
              продуктов для курения, в том числе кальянов, вейпов и сопутствующих товаров, изображения или текстов
              сексуального характера, порнографии). Лицензиар подтверждает или отклоняет запрос на проверку в
              соответствии с требованиями заполнения данных.</p>
            <p>Проверка/модерация информации/контента производится Лицензиаром только в части, размещенной на серверах
              Лицензиара и носит <strong>рекомендательный характер</strong> по причине того, что карты лояльности
              заполняются и настраиваются Лицензиатом самостоятельно под собственные нужды и концепцию заведения -
              Лицензиат должен самостоятельно ознакомиться с правилами Apple и Google предъявляемым к картам лояльности
              и самостоятельно удостовериться в факте нарушения/не нарушения правил площадок, Лицензиар не отвечает за
              убытки и упущенную выгоду в случае блокировки карты лояльности не соответствующих правилам Apple и Google.
            </p>
            <p>Модерация/проверка контента размещенного по ссылкам указанным Лицензиатом <strong>не
              производится</strong> по причине отсутствия доступа к модерации интернет-ресурса Лицензиата, а также по
              причине его изменяемости (динамичности наполнения), иными словами информация размещенная Лицензиатом по
              ссылкам, то есть не на серверах Лицензиата может меняться Лицензиаром в одностороннем порядке в любой
              момент времени без обязательного уведомления Лицензиара, таким образом Лицензиат самостоятельно несет
              ответственность в случае блокировки карты лояльности не соответствующих правилам Apple и Google</p>
            <p>После прохождения модерации у Лицензиата открывается доступ к формированию ссылки
              (https://<strong>облако</strong>.quickresto.ru/cpr/registration/) с анкетой для конечных пользователей в
              сервисе &laquo;Карта лояльности&raquo;. Лицензиат вправе размещать данную ссылку на любых ресурсах и в
              любом виде, в том числе в виде QR-кода.</p>
            <p>Клиент Лицензиата, как предприятия или сети предприятий общественного питания, вправе зарегистрироваться
              в сервисе &laquo;Карта лояльности&raquo;, заполнив анкету, указав свои Имя, Фамилию, телефон и дату
              рождения, и добавить карту в один из сервисов &ndash; AppleWallet или GooglePay. Регистрация в
              сервисе &laquo;Электронная карта лояльности&raquo; позволит клиенту лицензиата участвовать в программах
              лояльности Лицензиата &ndash; бонусных программах и иных, в том числе видеть в режиме онлайн количество
              доступных бонусов, статус клиента в бонусной программе и использовать QR-код/Штрих-код для авторизации в
              заведении Лицензиата.</p>
            <p>Сервис &laquo;Карта лояльности&raquo; является универсальным программным продуктом и предоставляется
              пользователям (Лицензиатам) в том виде &laquo;как есть&raquo;. Лицензиар не участвует в формировании и
              редактировании электронных карт клиентов Лицензиата, внесению в указанные карты необходимых данных и
              начислению на эти карты скидок и бонусов.</p>
            <p>3. Лицензиар гарантирует Лицензиату, что он является законным правообладателем программы для
              ЭВМ &ndash; Сервис &laquo;Карта лояльности&raquo;.</p>
            <p>4. Акцептом Лицензионного договора в части, касающейся Сервиса &laquo;Карта лояльности&raquo;, является
              факт оплаты лицензионного вознаграждения за использование Сервиса &laquo;Карта лояльности&raquo; согласно
              счету на оплату, выставленного Лицензиаром Лицензиату в соответствии с Тарифным планом, выбранным
              Лицензиатом.</p>
            <p>По итогам каждого периода использования прав на Сервис &laquo;Карта лояльности&raquo; Лицензиар
              направляет на электронную почту, указанную Лицензиатом, акт исполнения обязательств по настоящему
              Договору. При отсутствии у Лицензиата в течение 3 (трех) календарных дней с момента направления ему
              данного акта и отсутствия мотивированных возражений, данный акт считается согласованным и подписанным
              Лицензиатом, а услуги принятыми без претензий к качеству и срокам.</p>
            <p>Срок действия Лицензии на использование Сервиса &laquo;Карта лояльности&raquo; и срок доступа к нему
              определяются в соответствии с пунктом 4.2. Лицензионного договора на право использования программы для
              ЭВМ &laquo;Система автоматизации предприятий общественного питания &laquo;Quick Resto&raquo; и иных
              программных продуктов ООО &laquo;Квик
              Ресто&raquo; (далее &ndash; &laquo;Договор&raquo; или &laquo;Лицензионный договор&raquo;).</p>
            <p>5. В дальнейшем продление Лицензии и оплата вознаграждения за предоставление Лицензии на
              Сервис &laquo;Карта лояльности&raquo; осуществляется на условиях Лицензионного договора.</p>
            <p>6. Сервис &laquo;Карта лояльности&raquo; размещается Лицензиаром под его учетной записью в сервисе
              Google, ссылка на место размещения, которого предоставляется Лицензиаром Лицензиату. Лицензиат
              самостоятельно организовывает формирование электронных карт лояльности для своих клиентов. Сформированные
              электронные карты лояльности для их применения во взаимоотношениях Лицензиата со своими клиентами,
              подлежат добавлению последними в системы электронных платежей:Apple Wallet или Google Pay, в зависимости
              от вида мобильных устройств, используемых клиентами Лицензиата.</p>
            <p>Для создания пользователем карты лояльности необходимо подтверждение номера телефона, которое
              осуществляется через смс-сообщение или push-уведомление и позволяет идентифицировать пользователю по его
              абонентскому номеру. Эта авторизация осуществляется исключительно Лицензиаром, идентично опциональной
              программе Брендированное приложение WhiteLabel (Приложение №1 к Лицензионному договору).</p>
            <p>Электронные карты форматов Apple Wallet и Google Pay размещаются на инфраструктуре, принадлежащей
              компаниям Apple и Google и подчиняются правилам этих площадок.</p>
            <p>Сервис &laquo;Карта лояльности&raquo; &mdash; это сервис для предоставления доступа, настройки и
              обслуживания электронных карт вышеуказанных систем.</p>
            <p>7. Во всем ином, не предусмотренном настоящим Приложением № 3, применяются положения Лицензионного
              договора, а также положения действующего законодательства Российской Федерации.</p>
            <p>8. Краткое описание программы для ЭВМ - Сервиса &laquo;Электронная карта лояльности&raquo; и её
              возможностей, а также преимуществ содержится по следующей интернет ссылке: <u><a
                  href="https://quickresto.ru/passkit/">https://quickresto.ru/passkit/</a></u>.</p>
            <p>9. Лицензиат подтверждает, что он:</p>
            <p>&middot;&nbsp;перед заключением настоящего Приложения № 3 в полном объеме ознакомлен с функционалом
              (возможностями) Сервиса &laquo;Карта лояльности&raquo;, в том числе путем получения консультаций
              Лицензиара по использованию Сервиса &laquo;Карта лояльности&raquo; (по работе с Сервисом &laquo;Карта
              лояльности&raquo;) и по его установке;</p>
            <p>&middot; перед заключением настоящего Приложения № 3 в полном объеме ознакомлен с текстом Лицензионного
              договора на право использования программы для ЭВМ &laquo;Система автоматизации предприятий общественного
              питания &laquo;Quick Resto&raquo;&raquo; и иных программных продуктов ООО &laquo;Квик Ресто&raquo;, в
              котором содержатся все основные условия предоставления лицензий на программные продукты ООО &laquo;Квик
              Ресто&raquo; и который содержится по следующей интернет ссылке: <u><a
                  href="https://quickresto.ru/licenseAgreement/">https://quickresto.ru/licenseAgreement/</a></u>;</p>
            <p>&middot;&nbsp;осознаёт, что Сервис &laquo;Карта лояльности&raquo; является универсальным программным
              продуктом и предоставляется пользователям (Лицензиатам) в том виде &laquo;как есть&raquo;;</p>
            <p>- осознает, что функционирование карт лояльности возможно только при наличии оплаченной лицензии на
              систему автоматизации QuickResto.</p>
            <p>&middot; осознаёт, что, используя функциональные возможности Сервиса &laquo;Карта лояльности&raquo;,
              Лицензиат самостоятельно формирует и редактирует электронные карты лояльности для своих клиентов, а также
              вносит в указанные карты необходимые данные, начисляет на эти карты различные скидки и бонусы, в том числе
              в зависимости от заказов клиента блюд и напитков;</p>
            <p>- осознает, что карты лояльности, как и система лояльности не будут обновляться и работать без рабочего
              облака клиента. Как и не будет работать система лояльности.</p>
            <p>- осознает, что при неуплате продления Лицензии на сервис &laquo;Карта лояльности&raquo;, Лицензиар
              вправе отключить функционал сервиса на облаке Лицензиата и удалить сервис &laquo;Карта
              лояльности&raquo; Лицензиата;<br/> - Ознакомлен с политикой использования AppleWallet и GoogleWallet и
              осознает перечень запрещенных изображений/символик/наименований, а также какого вида/типа заведения
              Лицензиата использующие карты лояльности могут быть заблокированы за нарушение правил Apple и GoogleWallet
              (GoogleWalletAcceptablePolicy) &ndash; в случае блокировки аккаунт по причине нарушения Лицензиатом правил
              GoogleWallet и иных правил площадки Google Лицензиар не несет ответственности и не производит возврат
              денежных средств, в случае блокировки аккаунта Лицензиата Лицензиар не отвечает ни за какие убытки и
              упущенную выгоду.</p>
            <p>-&nbsp;заключает настоящее Приложение № 3, так как убедился в том, что Сервис &laquo;Карта
              лояльности&raquo; в полной мере соответствует потребностям и ожиданиям Лицензиата.</p>
            <p>- Несет расходы на авторизацию пользователей карты лояльности на основании п. 13.4 Лицензионного
              соглашения.</p>
            <p>- Не несет расходы на хранение пользовательских данных Лицензиата, а также оплату сертификата Apple
              Wallet и GooglePlay &ndash; только на период действия оплаченной Лицензии.</p>
            <p>10. В случае блокировки карты лояльности Лицензиата, Лицензиар за дополнительную плату производит
              повторное создание карты лояльности для Лицензиата.</p>

          </div>
        </div>
      </section>

      <CtaForm
          isFry={false}
          className={pageStyles.pageSection}
          isWhiteBackground
      />
    </DefaultLayout>
  )
}
